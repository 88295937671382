@import "../../../../../style/appStyle";
@import "./../../inputStyle";
.multi-select {
  width: 100%;
  position: relative;

  .p-multiselect {
    width: 100%;
    height: 100%;
    @include input-component;
    .p-multiselect-label {
      @include input-label-text;
    }

    .p-multiselect-trigger {
      @include dropdown-chevron-container;
    }

    .p-dropdown-label {
      @include dropdown-label;
    }
    .p-multiselect-items {
    }

    .pi-chevron-down {
      @include dropdown-chevron-down;
    }
  }

  .p-component {

    div:nth-child(1) {
      // Label-Container
      @include input-label-container;
    }
  }

  .p-multiselect-label-container {
    @include input-label-container;
  }

  .p-multiselect-label {
    @include input-label-text;
  }

  .input-label-container {
    .input-label-image-container {
      width: 100%;
      position: relative;
      .input-label-image {
        @include input-label-image;
      }
    }
    //.p-dropdown {
    //  width: 100%;
    //  .p-inputtext {
    //    @include input-label-text;
    //  }
    //}

    .p-multiselect-label {
      @include input-label-text;
      padding-left:7px !important;
    }

    .p-chevron-down {
      @include dropdown-chevron-down
    }
  }
}

