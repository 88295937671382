// Colors
$light-gray: #D5DAE0;
$fire-engine-red: #CD2129;
$color-nebula: #CED4DB;
$color-davy-grey: #4C535D;
$mercury-mist-color: #E3E7EB;


$input-control-font-style: "Liberation Sans", sans-serif !important;
$input-control-font-size: 14px;
$input-control-border:  1px solid $color-nebula !important;
$input-control-height: 50px;
$input-control-font-color: #6c757d;


// Roughly eq to a square in lucid
$standard-measurement: 12px;

// Navbar
$navbar-expanded-width: 202px;
$navbar-contracted-width: 55px;

// Header
$header-height: $standard-measurement*4;
$header-text-color: white;

// Red button
.btn-danger {
  background-color:$fire-engine-red ;
  border-color: $fire-engine-red;
}

.p-dropdown-panel {
  z-index: 2000 !important;
}
