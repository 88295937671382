@import "src/app/style/appStyle";

// Logo
$logo-wrapper-height: $header-height;
$logo-wrapper-width: $navbar-expanded-width;
$logo-width: auto;
$logo-height: $header-height * .6;

#layout-header {
  height: $header-height;
  max-height: $header-height;
  width: 100%;
  #TopNavbar {
    height: $header-height;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    z-index: 10;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.28);

    .logo-wrapper {
      width: $logo-wrapper-width;
      min-width: $logo-wrapper-width;
      height: $logo-wrapper-height;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 34px;
      border-bottom: 1px solid $light-gray;

      img {
        width: $logo-width;
        height: $logo-height;
      }
    }

    .arrow-button {
      border-radius: 50%;
      position: fixed;
      left: 180px;
      z-index: 5;
      background-color: $fire-engine-red !important;
      border-color: transparent;
    }

    .header-right-section {
      display: flex;
      flex-grow: 1;
      justify-content: end;
      .label.size-small {
        @media (min-width: 450px) {
          display: none;
        }
        font-size: 10px;
        color: $header-text-color;
        padding-right: 10px;
        padding-left: 24px;
      }
      .label.size-normal {
        @media (max-width: 450px) {
          display: none;
        }
        font-size: 14px;
        color: $header-text-color;
        padding: 12px 34px;
      }
      #sign-in-button {
        font-size: $input-control-font-size;
        margin-right: 30px;
        font-family: $input-control-font-style;
        background-color: $fire-engine-red !important;
        border-color: transparent;
      }
    }
  }
}