$platinum-color: #DEE2E6;
$section-desktop-border-color: $platinum-color;
$section-mobile-breakout: 1000px;

$toolbox-color: #606BBC;
$rhino-color: #2d335e;

$section-header-gradient-center: $toolbox-color;
$section-header-gradient-edges: $rhino-color;
$section-top-radius: 0.3rem;

$section-header-height: 35px;

.section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section {

  //background-color: red;
  @media(max-width: $section-mobile-breakout) {
    //Mobile
    border: solid 1px $section-desktop-border-color;
  }
  @media(min-width: $section-mobile-breakout) {
    //Desktop
    border: solid 1px $section-desktop-border-color;
    border-top-left-radius: $section-top-radius;
    border-top-right-radius: $section-top-radius;
  }

  .section-header {
    width: 100%;
    height: $section-header-height;
    display: flex;
    gap: 20px;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background: radial-gradient(
            circle at center,
            $section-header-gradient-center 4%,
            $section-header-gradient-edges 30%
    );
    svg {
      margin-left: 20px;
    }
    h4 {
      margin-left: 20px;
      color: white;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .section-body {

    @media(max-width: 912px) {
      margin: 0px;
    }
    margin: $section-header-height;
    //padding-left: 15px;
    //padding-right: 15px;
  }
}

.my-donations-header-section {
  border: none;
  .section-body {
    margin:0px;
  }
}