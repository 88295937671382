@import "../../../../../style/appStyle";

.icon-text-control {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  > img {
    height: 24px;
    width: 24px;
    align-self: center;
  }

  > div {
    align-self: center;
    margin-left: 10px;
    font-family: $input-control-font-style;
    font-size: $input-control-font-size;
  }
}