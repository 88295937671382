$light-gray: #D5DAE0;
$aqua-haze:#F2F3F5;
$link-water: #DFE3E8;
$lilly-white: #EDF5FF;
.appointment-slot {
  width: 100%;
  height: 90px;
  background-color:white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    .date {
      background-color: $link-water;
    }
    .detail-section, .status-badge {
      background-color: $lilly-white;
    }
  }

  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 100%;
    background-color: $aqua-haze;
    border-radius: 3px;
    text-align: center;
    padding: 4px;
    border: 1px solid $light-gray;
    p {
      font-weight: bold;
    }
    p.month {
      text-transform: uppercase;
      font-size: 10pt !important;
    }
    p.day {
      font-size: 14pt !important;
    }
  }
  .detail-section {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid $light-gray;
    padding: 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sub-title {
      font-size: 12px;
      margin-top: 4px;
    }

    .left-section {
      .title {
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 20px;
          height: auto;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .right-section {
      width: 20%;
      min-width: 180px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .labels {
        display: flex;
        flex-direction: column;
        svg {
          margin-right: 5px;
        }
        img {
          width: 30px;
          height: auto;
          cursor: pointer;
        }
      }
      .map-directions {
        display: flex;
        width: 40px;
        img {
          width: 40px;
          height: 40px
        }
      }
    }
  }

  .actions {
    height: 100%;
    border-radius: 3px;
    width: 340px;
    padding: 10px;
    border: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width:1024px) {
      flex-direction: column;
      width: 200px;
      button {
        width: 150px;
      }
    }

    &:hover {
      background-color: $lilly-white;
    }

    button {
      background-color: white;
      border-radius: 6px;
      padding: 7px 10px;
      border: 2px solid lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 10pt!important;
      img {
        width: 24px;
        height: auto;
      }
    }

  }
  .status-badge {
    width: 200px;
    height: 100%;
    border-radius: 3px;
    border: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top: 5px solid;
    gap: 10px;

    svg {
      width: 24px;
      height: 24px;
    }

    p {
      font-weight: bold;
    }
  }
}