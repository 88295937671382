@import "../../../../src/app/style/appStyle";

$banner-image-height: 75px;

$top-space-locator-text: 10px;
$bottom-space-locator-text: 5px;

// $opportunity-card-height: 175px; -- Removed due to overlapping of card text on narrow screens (AEP-59)
@mixin no-margin-or-padding-sides {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.opportunity-search-container {
  //background-color: orange;
  #banner-image-row {
    #banner-image-container {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  #banner-text-row {
    #banner-text-container {
      color: $color-davy-grey;
      font-weight: bold;
      border: none;
      padding-top: $top-space-locator-text;
      padding-bottom: $bottom-space-locator-text;
      &:focus {
          outline: none !important;
      }

      // Mobile
      @media (max-width: 768px)  {
        font-size: 24px !important;
      }

      // Desktop
      @media (min-width: 768px)  {
        font-size: 40px !important;
      }
    }
  }

  .mobile-container-css {
    .container, .container-fluid {
      @include no-margin-or-padding-sides;
    }
  }

  #opportunity-list-wrapper {
    flex-grow: 1;
    min-height: 300px;
    #opportunity-list {
      flex-grow: 0;
      overflow-y: auto;
      height: 0px;
      .opportunity-card {
        padding-top: 5px;
        // height: $opportunity-card-height; -- Removed due to overlapping of card text on narrow screens (AEP-59)
        .opportunity-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .card {
          height: 100%;
        }
      }
    }
  }

  #opportunity-map-row {
    height: 100%;
  }
}

.opportunity-search-container.op-full {
  #opportunity-list {
  }
}

.opportunity-search-container.landing-layout{
  #opportunity-list {
    height: 0px;
  }
}