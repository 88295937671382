@import "src/app/style/appStyle";

.no-future-appointments-section {
  font-size: 14px;

  &.desktop-section-css {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  &.mobile-section-css {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .make-appointment-button {
    width: 250px;
    font-size: 12px;
    background-color: $fire-engine-red !important;
    border-color: $fire-engine-red !important;
  }
}