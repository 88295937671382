@import "../../../style/appStyle";

$filter-mobile-breakout: 1000px;
$mobile-filter-border-color: $mercury-mist-color;

.filter-control-wrapper {

  padding-top:2px;
  padding-bottom:8px;

}
.filter-input-form {
  border: 1px solid rgba(0,0,0,0.175);
  border-radius: 0.375rem;
  padding: 10px;

  margin-bottom: 10px;

  .filter-input-form-row {
    display: flex;

    &.stretch {
      justify-content: space-between;
      .filter-control-wrapper {
        display: flex;
        height: 100%;

        &.grow {
          flex-grow: 1;
        }
      }

      @media(min-width: $filter-mobile-breakout) {
        //Desktop
        flex-direction: row;
        justify-content: space-between;
        //border: 1px solid $mobile-filter-border-color;
        gap: 0.35vw;
      }
    }

    &.no-stretch {
      justify-content: flex-start;
    }

    //background-color:red;

    @media(max-width: $filter-mobile-breakout) {
      //Mobile
      flex-direction: column;
     // gap: 20px;
    }
  }
}